import React from "react"

// Comps
import Container from "../components/container/Container"
import Header from "../components/headers/Header"
import Metas from "../components/meta/Meta"

// Parts
import PanelAbout from "../components/panels/PanelAbout"
import PanelClients from "../components/panels/PanelClients"
import PanelContact from "../components/panels/PanelContact"

const Homepage = () => {
    return (
        <Container>
            <Metas />
            <Header />
            <PanelAbout />
            <PanelClients />
            <PanelContact />
        </Container>
    )
}

export default Homepage
