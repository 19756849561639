import React from "react"

// Comps
import { Row, Cell, Panel } from "../layout/Layout"
import List from "../lists/List"

// Queries
import { StaticQuery, graphql } from "gatsby"

const PanelClients = () => {
    return (
        <StaticQuery
            query={PanelClientsQuery}
            render={data => (
                <Panel size="48">
                    <Row>
                        {data.allPanelProjectJson.edges.map(
                            ({ node }, index) => (
                                <Cell start="0" end="12" key={index}>
                                    <h2 className="teasing-1 trailer-16">
                                        {node.panelTitle}
                                    </h2>
                                    <List items={node.items} />
                                </Cell>
                            )
                        )}
                    </Row>
                </Panel>
            )}
        />
    )
}

const PanelClientsQuery = graphql`
    query PanelClientsQuery {
        allPanelProjectJson {
            edges {
                node {
                    panelTitle
                    items {
                        label
                        url
                    }
                }
            }
        }
    }
`

export default PanelClients
