import React from "react"

// Styles
import listStyles from "./List.module.styl"

const ListItem = props => {
    return (
        <li className={listStyles.ListItem}>
            {props.item.url && (
                <a
                    className="link"
                    href={props.item.url}
                    rel="noopener noreferrer"
                    target="_blank"
                    title={props.item.label}
                >
                    {props.item.label}
                </a>
            )}
            {!props.item.url && <span>{props.item.label}</span>}
        </li>
    )
}

export default ListItem
