import React from "react"

// Comps
import ListItem from "./ListItem"

// Styles
import listStyles from "./List.module.styl"

const List = props => {
    return (
        <ul className={listStyles.List}>
            {props.items.map((item, index) => (
                <ListItem
                    key={index}
                    item={item}
                    isLast={index === props.items.length - 1}
                />
            ))}
        </ul>
    )
}

export default List
