/* eslint-disable */

import React from "react"

// Comps
import { Row, Cell, Panel } from "../layout/Layout"

// Libs
import * as moment from "moment"

// Queries
import { StaticQuery, graphql } from "gatsby"

class PanelAbout extends React.Component {
    constructor(props) {
        super(props)
    }

    state = {
        birthDate: Math.abs(
            moment([1991, 7, 18]).diff(moment(), "years", true)
        ).toFixed(9),
    }

    componentDidMount() {
        this.interval = setInterval(() => {
            this.setState({
                birthDate: Math.abs(
                    moment([1991, 7, 18]).diff(moment(), "years", true)
                ).toFixed(9),
            })
        }, 48)
    }

    render() {
        return (
            <StaticQuery
                query={PanelAboutQuery}
                render={data => (
                    <Panel size="48">
                        <Row>
                            {data.allPanelAboutJson.edges.map(
                                ({ node }, index) => (
                                    <Cell start="0" end="12" key={index}>
                                        <h2 className="teasing-1 trailer-16">
                                            {node.panelTitle}
                                        </h2>
                                        <p
                                            className="u-f-tabular"
                                            dangerouslySetInnerHTML={{
                                                __html: `I am a ${this.state.birthDate} years old ${node.panelText}`,
                                            }}
                                        ></p>
                                    </Cell>
                                )
                            )}
                        </Row>
                    </Panel>
                )}
            />
        )
    }
}

const PanelAboutQuery = graphql`
    query PanelAboutQuery {
        allPanelAboutJson {
            edges {
                node {
                    panelTitle
                    panelText
                }
            }
        }
    }
`

export default PanelAbout
